import { default as NextLink } from 'next/link';
import { PropsWithChildren, ReactElement } from 'react';
import { ParsedUrlQueryInput } from 'node:querystring';

interface Link {
  slug: string;
  query?: string | null | ParsedUrlQueryInput;
}

export default function Link({ children, slug, query }: PropsWithChildren<Link>): ReactElement {
  let pathname;
  pathname = slug.startsWith('/') ? slug : `/${slug}`;
  pathname = pathname === '/home' ? '/' : pathname;
  return <NextLink href={{ pathname, query }}>{children}</NextLink>;
}
