import axios, { AxiosInstance } from 'axios';

export function getBaseUrl(siteId: string): string {
  return `https://${siteId}.a.searchspring.io`;
}

export default function client(siteId: string): AxiosInstance {
  return axios.create({
    baseURL: getBaseUrl(siteId),
  });
}
export const ASCENDING = 'asc';
export const DESCENDING = 'desc';

export type SortDirection = typeof ASCENDING | typeof DESCENDING;

export interface VariantOptions {
  color?: string;
  price: string;
  facet_match?: boolean;
  sku: string;
  upc: string;
  vto: boolean;
  primary_image: string | null;
  secondary_images: string[];
}
export interface SearchResult {
  uid: string;
  name: string;
  brand: string;
  sku: string;
  price: string;
  children: string;
  description: string;
  imageUrl: string;
  intellisuggestData: string;
  intellisuggestSignature: string;
}
export enum FacetType {
  VALUE = 'value',
  RANGE = 'range',
}

export interface FacetValue {
  type: FacetType;
  active: boolean;
  value?: string;
  label: string;
  count: number;
  high?: string;
  low?: string;
}
export interface SearchFacet {
  field: string;
  label: string;
  values: FacetValue[];
}

export interface Breadcrumb {
  field: string;
  label: string;
}

export interface SortOption {
  field: string;
  direction: SortDirection;
  label: string;
}

export interface SearchResponse {
  pagination: {
    totalResults: number;
    totalPages: number;
    currentPage: number;
  };

  sorting: {
    options: SortOption[];
  };

  breadcrumbs: Breadcrumb[];

  results: SearchResult[];

  facets: SearchFacet[];
}
