import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { Close } from '@material-ui/icons';
import { Drawer, makeStyles } from '@material-ui/core';
import useResizeObserver from '../../../lib/resize';
import { useRouter } from 'next/router';

import MobileNavLink from './MobileNavLink';
import MobileSubNavLink from './MobileSubNavLink';
import CTA from '../Cta';
import { isNavLinkGuard, NavHeaderLink } from 'types/nav';
import { IFragmentCta } from 'types/contentful';
import Search from '../Search';
import { IFragmentPhoneNumber } from 'types/contentful';
import HeaderContactNumbers from 'components/UI/HeaderContactNumber';

interface MobileNavProps {
  isOpen: boolean;
  close: Dispatch<SetStateAction<boolean>>;
  toggle: (close: boolean) => () => void;
  currentSlug: string;
  links: NavHeaderLink[];
  hideTopNavCtas?: boolean;
  ctas?: IFragmentCta[];
  numbers?: IFragmentPhoneNumber[];
}

const useStyles = makeStyles({
  root: {
    width: '83.333333%',
    backgroundColor: '#F6F6F6',
    justifyContent: 'space-between',
    overflowY: 'hidden',
  },
});

export default function MobileNav({
  isOpen,
  close,
  toggle,
  currentSlug,
  links,
  hideTopNavCtas,
  ctas,
  numbers,
}: MobileNavProps): ReactElement {
  const [shadow, setShadow] = useState(false);
  const router = useRouter();
  const classes = useStyles();
  // watch nav height to add shadow to cta footer
  const { ref } = useResizeObserver<HTMLDivElement>({
    onResize: ({ height }) => {
      const nav = document.getElementById('mobileNav');

      (height as number) > (nav?.clientHeight as number) ? setShadow(true) : setShadow(false);
    },
  });

  useEffect(() => {
    close(false);
  }, [router.asPath, close]);

  // prevent site from scrolling when nav open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  // link list
  const linkList = links.map((link, index: number) => {
    if (isNavLinkGuard(link)) {
      // top level nav link
      return <MobileNavLink key={index} currentSlug={currentSlug} {...link.fields} />;
    } else {
      // sub nav link
      return <MobileSubNavLink key={index} link={link} currentSlug={currentSlug} close={close} />;
    }
  });

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onBackdropClick={toggle(false)}
      onEscapeKeyDown={toggle(false)}
      onClose={() => toggle(false)}
      classes={{ paper: classes.root }}
    >
      <div className="w-full text-right pr-6 pt-6">
        <button role="menu" aria-label="close navigation menu" onClick={toggle(false)}>
          <Close fontSize="large" />
        </button>
      </div>

      <div id="mobileNav" className="overflow-auto flex-grow my-4">
        <nav ref={ref} role="navigation" className="pr-6 pl-10 space-y-6 overflow-y-scroll">
          <Search onClick={toggle(false)} />
          {linkList}
        </nav>
      </div>

      {!hideTopNavCtas && ctas && (
        <div
          className=" w-full bg-grey pt-4 pb-10 px-4 space-y-4"
          style={{ boxShadow: `${shadow ? '0px 0px 20px rgba(0, 0, 0, 0.15)' : ''}` }}
        >
          {numbers && <HeaderContactNumbers numbers={numbers} isMobile={true} />}
          {ctas.map((cta, index) => {
            return <CTA key={index} {...cta.fields} backgroundColor="black" />;
          })}
        </div>
      )}
    </Drawer>
  );
}
