import { ReactElement, useState, Fragment } from 'react';
import { ExitToApp } from '@material-ui/icons';
import { Transition } from '@headlessui/react';

export default function ExitPreview(): ReactElement {
  const [minimized, setMinimized] = useState(false);

  return (
    <div className="fixed bottom-0 w-full">
      <Transition
        show={!minimized}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="container overflow-visible">
          <div className="flex flex-col sm:flex-row justify-between text-white p-4 sm:mb-5 rounded-lg sm:items-center bg-red">
            <div>
              <span className="mr-2 font-semibold text-lg">Site Preview:</span>
              Any unpublished or draft content is viewable.
            </div>
            <div className="sm:ml-10 mt-4 sm:mt-0 space-x-2 flex">
              <button
                onClick={() => setMinimized(!minimized)}
                className="font-bold text-sm bg-white text-red rounded px-4 py-2 w-1/2 sm:w-auto"
              >
                Minimize
              </button>
              <button
                onClick={() => (window.location.href = '/api/exit-preview')}
                className="font-bold text-sm whitespace-no-wrap bg-white text-red rounded px-4 py-2 w-1/2 sm:w-auto"
              >
                Exit Preview
              </button>
            </div>
          </div>
        </div>
      </Transition>

      <Transition
        show={minimized}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <button
          onClick={() => setMinimized(!minimized)}
          className="bg-red rounded-full p-4 text-white fixed bottom-0 right-0 m-5"
        >
          <ExitToApp className="transform rotate-180" />
        </button>
      </Transition>
    </div>
  );
}
