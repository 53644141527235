import { buildProductUrl } from 'lib/product';
import { SearchResultWithVariants } from 'lib/searchspring';
import React, { ReactElement, useContext } from 'react';
import Link from '../Link';
import ProductSearchImage from './ProductSearchImage';
import SiteDataContext from 'context/SiteDataContext';

interface SearchProductCard extends SearchResultWithVariants {
  handleClose?: () => void;
}

export default function SearchProductCard({
  uid,
  brand,
  price,
  name,
  variants,
  handleClose,
}: SearchProductCard): ReactElement {
  const { ecommIds } = useContext(SiteDataContext);
  const [sku, options] = Object.entries(variants)[0];
  const slug = buildProductUrl(name, options.color, uid, sku);
  const showPricing = ecommIds?.fields.showPricing;

  const onLinkClick = (): void => {
    if (handleClose) {
      handleClose();
    }
  };

  return (
    <li className="p-2 md:w-1/3">
      <Link slug={slug}>
        <a role="link" tabIndex={0} aria-label={name} onClick={onLinkClick}>
          <div className="shadow-card p-4">
            <ProductSearchImage imageUrl={options.primary_image || ''} productName={name} />
            <div className="flex justify-between items-end mt-4">
              <div className="flex flex-col">
                <span className="font-bold">{brand}</span>
                <span className="opacity-50">{name}</span>
              </div>
              {showPricing && <span className="opacity-50">${price}</span>}
            </div>
          </div>
        </a>
      </Link>
    </li>
  );
}
