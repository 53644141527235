import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { ReactElement } from 'react';

interface WarningBannerProps {
  text: string;
}

export default function WarningBanner({ text }: WarningBannerProps): ReactElement {
  return (
    <div className="py-4 px-2 bg-red text-white flex justify-center items-center">
      <ErrorIcon style={{ fontSize: 20, marginRight: '6px' }} />
      {text}
    </div>
  );
}
