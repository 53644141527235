import { Fragment, KeyboardEvent, ReactElement, useEffect } from 'react';
import { ChevronRight } from '@material-ui/icons';
import { Transition } from '@headlessui/react';

import useClickAway from '../../../hooks/useClickAway';
import { useRouter } from 'next/router';
import { INavSubNav } from 'types/contentful';
import NavLink from './NavLink';

interface SubNavLinkProps {
  link: INavSubNav;
  currentSlug: string;
}

export default function SubNavLink({ link, currentSlug }: SubNavLinkProps): ReactElement {
  const { ref, isVisible, setIsVisible } = useClickAway(false);
  const { asPath } = useRouter();

  const toggle = (e: KeyboardEvent<HTMLDivElement>): void => {
    e.preventDefault();

    if (e.type === 'keypress' && e.code === 'Space') {
      setIsVisible(!isVisible);
    }
  };

  useEffect(() => {
    setIsVisible(false);
  }, [asPath, setIsVisible]);

  return (
    <div
      ref={ref}
      role="menuitem"
      tabIndex={0}
      className="flex-none mr-2 relative cursor-pointer "
      onClick={() => setIsVisible(!isVisible)}
      onKeyPress={(e) => toggle(e)}
    >
      <div
        role="button"
        tabIndex={0}
        className="py-4 text-secondary"
        // onMouseDown={() => setIsVisible(!isVisible)}
      >
        {link.fields.text}
        <div
          className={`inline-block transition transform duration-300 text-tertiary-actual ${
            isVisible ? '-rotate-90' : 'rotate-90'
          }`}
        >
          <ChevronRight />
        </div>
      </div>

      <Transition
        show={isVisible}
        as={Fragment}
        unmount={false}
        enter="transition ease-in-out duration-300"
        enterFrom="opacity-75 transform scale-y-0 -translate-y-1/2"
        enterTo="opacity-100 transform scale-y-100 translate-y-0"
        leave="transition ease-out duration-300"
        leaveFrom="opacity-100 transform scale-y-100 translate-y-0"
        leaveTo="opacity-0 transform scale-y-0 -translate-y-1/2"
      >
        <div
          className="absolute -ml-8 -mt-2 bg-white p-6 w-56"
          style={{ boxShadow: '0px 15px 15px rgba(0, 0, 0, 0.15)' }}
        >
          <div>
            {link.fields.links.map((link, i, array) => {
              return (
                <NavLink
                  key={i + link.fields.text}
                  currentSlug={currentSlug}
                  classNames="block mb-6 last:mb-0"
                  onBlur={(e) => {
                    e.stopPropagation();
                    if (array.length - 1 === i) {
                      setIsVisible(false);
                    }
                  }}
                  {...link.fields}
                />
              );
            })}
          </div>
        </div>
      </Transition>
    </div>
  );
}
