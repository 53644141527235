import React, { PropsWithChildren, ReactElement } from 'react';
import cssVars from 'css-vars-ponyfill';
import SiteDataContext from 'context/SiteDataContext';
import { BrandedSiteProps, PageDates } from 'lib/routing';
import TopNav from 'components/Contentful/Header';
import FooterNav from 'components/Contentful/Footer';
import ExitPreview from './ExitPreview';
import Head from 'next/head';
import WarningBanner from './WarningBanner';
import { NextSeo, NextSeoProps } from 'next-seo';
import { ScrollProvider, ScrollContext } from 'context/ScrollContext';
import { useRouter } from 'next/router';
import OrganizationData from 'components/StructuredData/OrganizationData';
import Script from 'next/script';
import SubscriptionLightbox from './SubscriptionLightbox';

interface ICordial {
  (
    a: string,
    b: { email: string },
    c: {
      channels: { email: { subscribeStatus: string; address: string } };
      cell: string;
      welcome_list: boolean;
    },
    d: { forceSubscribe: { email: boolean } }
  ): void;
}

declare global {
  interface Window {
    crdl: ICordial;
    dataLayer?: Record<string, unknown>[];
    IntelliSuggest: {
      init: (props: { siteId: string; context: string; seed: string[] }) => void;
      viewItem: (props: { sku: string }) => void;
    };
  }
}

interface CustomNextSeoProps extends NextSeoProps {
  description: string;
  title: string;
}

interface LayoutProps {
  siteData: BrandedSiteProps;
  preview: boolean;
  currentSlug: string;
  noIndexNoFollow?: boolean;
  warningBanners?: string[];
  nextSeoProps: CustomNextSeoProps;
  pageDates?: PageDates;
  hideTopNavCtas?: boolean;
}

export default function Layout({
  noIndexNoFollow,
  currentSlug,
  preview,
  siteData,
  children,
  warningBanners = [],
  nextSeoProps,
  pageDates,
  hideTopNavCtas,
}: PropsWithChildren<LayoutProps>): ReactElement {
  const {
    siteName,
    topNav,
    footerNav,
    domain,
    logo,
    favicon,
    googleTagManagerId,
    gaMeasurementIdOrUATrackingId,
    // optimizelyProjectId,
    cordialMetaData,
    branding,
    subscriptionLightbox,
    metaPhoneNumber,
  } = siteData;
  const {
    primaryColor,
    secondaryColor,
    tertiaryColor,
    highlightColor,
    mutedColor,
    neutralColor,
    primaryTextColor,
    secondaryTextColor,
    tertiaryTextColor,
    highlightTextColor,
    mutedTextColor,
    neutralTextColor,
  } = branding;

  cssVars({
    // Sources
    include: 'link[rel=stylesheet],style',
    variables: {
      '--primary': primaryColor || '',
      '--secondary': secondaryColor || '',
      '--tertiary': tertiaryColor || '',
      '--highlight': highlightColor || '',
      '--muted': mutedColor || '',
      '--neutral': neutralColor || '',
      '--primaryText': primaryTextColor || '',
      '--secondaryText': secondaryTextColor || '',
      '--tertiaryText': tertiaryTextColor || '',
      '--highlightText': highlightTextColor || '',
      '--mutedText': mutedTextColor || '',
      '--neutralText': neutralTextColor || '',
      '--primaryTextActual': primaryColor || '',
      '--secondaryTextActual': secondaryColor || '',
      '--tertiaryTextActual': tertiaryColor || '',
      '--highlightTextActual': highlightColor || '',
      '--mutedTextActual': mutedColor || '',
      '--neutralTextActual': neutralColor || '',
      '--divide-opacity': '1',
      '--border-opacity': '1',
      '--text-opacity': '1',
      '--bg-opacity': '1',
      '--locationFilterToogleChecked': '#ffdd04',
      '--locationFilterToogleUnChecked': '#f6f6f6',
      '--locationBar': '#013b4a',
    },

    // Options
    onlyLegacy: true,
    updateURLs: false,
  });

  const pageTitle = `${nextSeoProps.title} | ${siteName}`;
  const currentUrl = `https://${siteData.domain}${currentSlug ? '/' + currentSlug : ''}`;
  const { asPath } = useRouter();
  const fullUrl = `https://${siteData.domain}${asPath.split('?')[0]}`.replace(/\/$/, '');

  const additionalMetaPhoneTags = (metaPhoneNumber || []).map((phone: string) => {
    return {
      property: 'dc:phone_number',
      content: phone,
      keyOverride: `phonenumber${phone}`,
    };
  });

  return (
    <SiteDataContext.Provider
      value={{
        domain,
        ecommIds: siteData.ecommIds,
        colors: branding,
      }}
    >
      <Head>
        {favicon && <link rel="shortcut icon" href={`${favicon.fields.file.url}?w=48&h=48`} />}
        {/*{optimizelyProjectId && (*/}
        {/*  <script src={`https://cdn.optimizely.com/js/${optimizelyProjectId}.js`}></script>*/}
        {/*)}*/}
        {googleTagManagerId && (
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${googleTagManagerId}');`,
            }}
          />
        )}

        {gaMeasurementIdOrUATrackingId && (
          <>
            <script>
              {`
          (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
          (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
          m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
          })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

          ga('create', '${gaMeasurementIdOrUATrackingId}', 'auto');
          ga('send', 'pageview');
        `}
            </script>
          </>
        )}
      </Head>
      {/* Structured Data - Organization - this needs to be outside of the <Head> tag */}
      <OrganizationData
        siteData={siteData}
        asPath={asPath}
        meta={nextSeoProps}
        pageDates={pageDates}
      />
      <NextSeo
        canonical={fullUrl}
        {...nextSeoProps}
        openGraph={{
          ...nextSeoProps.openGraph,
          title: pageTitle,
          description: nextSeoProps.openGraph?.description || nextSeoProps.description,
          site_name: siteData.siteName,
          url: currentUrl,
        }}
        noindex={noIndexNoFollow}
        nofollow={noIndexNoFollow}
        title={pageTitle}
        additionalMetaTags={[...additionalMetaPhoneTags]}
      />
      {googleTagManagerId && (
        <noscript>
          <iframe
            title="gtm noscript"
            src={`https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          ></iframe>
        </noscript>
      )}
      <ScrollProvider>
        <ScrollContext.Consumer>
          {({ headerHeight }) => (
            <>
              <TopNav
                {...topNav}
                logo={logo}
                siteName={siteName}
                currentSlug={currentSlug}
                hideTopNavCtas={hideTopNavCtas}
              />

              <div
                className="max:container max-w-screen-max font-body"
                style={{ marginTop: headerHeight, flex: '1 0 auto' }}
              >
                <div className="flex flex-col flex-1 min-h-full">
                  {warningBanners.map((bannerText, i) => (
                    <WarningBanner key={i} text={bannerText} />
                  ))}
                  {children}
                </div>
              </div>
              <FooterNav
                currentSlug={currentSlug}
                {...footerNav}
                socialMediaLink={siteData.socialMediaLink}
              />

              <Script id="show-banner" strategy="lazyOnload">
                {`
                  let anchorTags = document.querySelectorAll(".openInNewTab a");
                  for (var i = 0; i < anchorTags.length; i++) {
                    //console.log('anchorTags: ', anchorTags[i]);
                    anchorTags[i].setAttribute("target", "_blank");
                  }
                `}
              </Script>
            </>
          )}
        </ScrollContext.Consumer>
      </ScrollProvider>
      {cordialMetaData?.fields.connectID && (
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(C,O,R,D,I,A,L){
          C.CordialObject=I,C[I]=C[I]||function(){(C[I].q=C[I].q||[]).push(arguments)};
          C[I].l=1*new Date,C[I].q=[],A=O.createElement(R);
          L=O.getElementsByTagName(R)[0],A.async=1,A.src=D,L.parentNode.insertBefore(A,L);
        })(window, document, 'script', '${cordialMetaData.fields.connectURL}/track.v2.js', 'crdl');
        crdl('connect', '${cordialMetaData.fields.connectID}', { 
            trackUrl: "${cordialMetaData.fields.trackURL}" ,
            connectUrl: "${cordialMetaData.fields.connectURL}" ,
            cookieDomain: "${cordialMetaData.fields.cookieDomain}",
            cookieLife: 365
        });`,
          }}
        />
      )}
      {preview && <ExitPreview />}
      {subscriptionLightbox && (
        <SubscriptionLightbox siteName={siteName} lightboxData={subscriptionLightbox.fields} />
      )}
      {/* :::::Script for User way::::: */}
      {/* White label script */}
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(d){var s = d.createElement("script");s.setAttribute("data-account", "cAaFh0uGdu");s.setAttribute("src", "https://cdn.userway.org/widget.js");(d.body || d.head).appendChild(s);})(document)`,
        }}
      ></script>
      <noscript>
        Please ensure Javascript is enabled for purposes of
        <a href="https://userway.org">website accessibility</a>
      </noscript>
      {/* :::::End of Script for User way::::: */}
    </SiteDataContext.Provider>
  );
}
