import { useScrollPosition } from 'hooks/useScrollPosition';
import { MAX_SCREEN_WIDTH } from 'lib/consts';
import { createContext, useState, ReactNode, useEffect } from 'react';

interface IScrollContext {
  scroll: number;
  navShowing: boolean;
  setNavShowing: (showing: boolean) => void;
  filterTop: number;
  setFilterTop: (top: number) => void;
  headerHeight: number;
  setHeaderHeight: (top: number) => void;
  stickyFilters: boolean;
  setStickyFilters: (state: boolean) => void;
  isMobile: boolean;
}

const DEFAULT_HEADER_HEIGHT = 160;

export const ScrollContext = createContext<IScrollContext>({
  scroll: 0,
  navShowing: true,
  setNavShowing: () => {
    return;
  },
  filterTop: 0,
  setFilterTop: () => {
    return;
  },
  headerHeight: DEFAULT_HEADER_HEIGHT,
  setHeaderHeight: () => {
    return;
  },
  stickyFilters: false,
  setStickyFilters: () => {
    return;
  },
  isMobile: false,
});

export const ScrollProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [scroll, setScroll] = useState(0);
  const [filterTop, setFilterTop] = useState<number>(0);
  const [navShowing, setNavShowing] = useState(true);
  const [headerHeight, setHeaderHeight] = useState(DEFAULT_HEADER_HEIGHT);
  const [stickyFilters, setStickyFilters] = useState(false);
  const [windowWidth, setWindowWidth] = useState(MAX_SCREEN_WIDTH);
  const isMobile = windowWidth <= 768 ? true : false;

  useEffect(() => {
    if (window) {
      setWindowWidth(window.innerWidth);
    }
  }, []);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      setScroll(currPos.y);
      if (window.innerWidth !== windowWidth) setWindowWidth(window.innerWidth);

      if (filterTop) {
        // if filtersSection is at bottom of nav accounting for padding
        const paddingAdjust = isMobile ? -20 : 20;
        currPos.y >= filterTop - headerHeight + paddingAdjust
          ? setStickyFilters(true)
          : setStickyFilters(false);

        // if scroll is past filtersection
        if (currPos.y > filterTop) {
          // if user scrolls back up the page & nav !navShowing show nav
          const isShow = currPos.y < prevPos.y;
          if (isShow !== navShowing) setNavShowing(isShow);
        } else {
          // set nav showing if scroll above filters
          setNavShowing(currPos.y <= filterTop);
        }
      }
    },
    [navShowing, stickyFilters]
  );

  return (
    <ScrollContext.Provider
      value={{
        scroll,
        filterTop,
        setFilterTop,
        navShowing,
        setNavShowing,
        headerHeight,
        setHeaderHeight,
        stickyFilters,
        setStickyFilters,
        isMobile,
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
};
