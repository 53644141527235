import { ReactElement } from 'react';
import { escapeHtml } from 'lib/util';
import Head from 'next/head';
import { JsonObject } from 'lib/json';

export default function SchemaOrg({ json }: { json: JsonObject }): ReactElement {
  // The type system ensures that invalid types (e.g. undefined) don't appear in the JSON
  const safeData = escapeHtml(JSON.stringify(json));

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: safeData,
        }}
      ></script>
    </Head>
  );
}

export function orgId(domain: string): string {
  return `https://${domain}/#organization`;
}

export function locationId(domain: string): string {
  return `https://${domain}/#optometric`;
}
