import { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { Asset } from 'contentful';
import { Menu } from '@material-ui/icons';

import Link from '../UI/Link';
import Image from '../UI/Image';
import NavLink from '../UI/Nav/NavLink';
import SubNavLink from '../UI/Nav/SubNavLink';
import MobileNav from '../UI/MobileNav/MobileNav';
import CTA from '../UI/Cta';
import { isNavLinkGuard } from 'types/nav';
import { INavHeaderFields } from 'types/contentful';
import dynamic from 'next/dynamic';
import { ScrollContext } from 'context/ScrollContext';
import HeaderContactNumbers from 'components/UI/HeaderContactNumber';
import HeaderAlertBanner from 'components/UI/HeaderAlertBanner';
import PromotionalBanner from 'components/UI/PromotionalBanner';

const Search = dynamic(() => import('components/UI/Search'), { ssr: false });

interface TopNavProps extends INavHeaderFields {
  logo: Asset;
  currentSlug: string;
  siteName: string;
  hideTopNavCtas?: boolean;
}

export default function TopNav({
  links,
  searchEnabled,
  logo,
  currentSlug,
  ctas,
  hideTopNavCtas,
  phoneNumbers,
  alertBanner,
  headerType,
  belowHeaderBanner,
}: TopNavProps): ReactElement {
  const [mobileNav, setMobileNav] = useState(false);
  const [onBannerRemove, setOnBannerRemove] = useState<boolean>(false);
  const { navShowing, setHeaderHeight, headerHeight } = useContext(ScrollContext);
  const ref = useRef<HTMLElement>(null);
  let headerTypeClass = '';

  useEffect(() => {
    if (ref.current) {
      setHeaderHeight(ref.current.clientHeight);
    }
  }, [setHeaderHeight, ref?.current?.clientHeight, onBannerRemove]);

  // If headerType is ECPRebranding only than add the styleclass
  // ecp-rebrand-header to the dummy layer
  if (headerType === 'Rebranding') {
    headerTypeClass = 'ecp-rebrand-header';
  }

  // nav links
  const linkList = links
    ? links.map((link, index) => {
        if (isNavLinkGuard(link)) {
          // top level nav link
          return (
            <NavLink
              key={index}
              currentSlug={currentSlug}
              classNames={`flex-none py-4 text-secondary ${
                currentSlug === link.fields.slug ? 'border-b-3 border-black font-bold' : ''
              }`}
              {...link.fields}
            />
          );
        } else {
          // sub nav link
          return <SubNavLink key={index} link={link} currentSlug={currentSlug} />;
        }
      })
    : [];

  // callback for mobile nav drawer
  const toggleDrawer = (open: boolean) => () => {
    setMobileNav(open);
  };

  return (
    <header
      ref={ref}
      className="w-screen fixed bg-white z-20 transition-all ease-out duration-300 flex-none"
      style={{
        boxShadow: '0px 4px 14px rgba(100, 100, 100, 0.15)',
        top: navShowing ? 0 : headerHeight * -1,
        zIndex: 50,
      }}
    >
      {alertBanner && (
        <HeaderAlertBanner
          text={alertBanner.fields.bannerText}
          colorScheme={alertBanner.fields.colorScheme}
          openInNewTab={alertBanner.fields.openInNewTab}
          setOnBannerRemove={setOnBannerRemove}
        />
      )}

      <MobileNav
        isOpen={mobileNav}
        toggle={toggleDrawer}
        close={setMobileNav}
        currentSlug={currentSlug}
        links={links ? links : []}
        hideTopNavCtas={hideTopNavCtas}
        ctas={ctas}
        numbers={phoneNumbers}
      />

      <div className="max:container max-w-screen-max">
        <div className="flex justify-between items-center px-4 lg:px-6 py-6 lg:pb-0">
          <div className={headerTypeClass}></div>
          <div className="flex-auto cursor-pointer">
            <Link slug="home">
              <a aria-label="home">
                <Image
                  image={logo}
                  query="h=60"
                  classNames="sm:max-w-300 max-w-200 max-h-40 sm:max-h-60"
                />
              </a>
            </Link>
          </div>

          {!hideTopNavCtas && (
            <div className="hidden lg:flex">
              {phoneNumbers && <HeaderContactNumbers numbers={phoneNumbers} />}

              {ctas &&
                ctas.map((cta, index) => {
                  return (
                    <CTA
                      key={index}
                      {...cta.fields}
                      backgroundColor="black"
                      classNames={index !== ctas.length - 1 ? 'mr-2' : ''}
                    />
                  );
                })}
            </div>
          )}

          {/* mobile nav menu button */}
          <button
            role="menu"
            aria-label="open navigation menu"
            className="lg:hidden self-center"
            onClick={toggleDrawer(true)}
          >
            <Menu fontSize="large" />
          </button>
        </div>
        <nav role="navigation" className="hidden lg:flex justify-between px-6 mt-5">
          <div className="w-3/4 flex flex-row justify-between">{linkList}</div>
          <div className="w-1/4 flex justify-end items-center">{searchEnabled && <Search />}</div>
        </nav>
      </div>

      {belowHeaderBanner && <PromotionalBanner {...belowHeaderBanner.fields} />}
    </header>
  );
}
