import { INavLink, INavSubNav } from 'types/contentful';

export enum NavHeaderLinkTypes {
  NavLink = 'navLink',
  NavSubNav = 'navSubNav',
}

export type NavHeaderLink = INavLink | INavSubNav;

export const isNavLinkGuard = (navHeaderLink: NavHeaderLink): navHeaderLink is INavLink => {
  return (navHeaderLink as INavLink).sys.contentType.sys.id === NavHeaderLinkTypes.NavLink;
};

export const isNavSubNavGuard = (navHeaderLink: NavHeaderLink): navHeaderLink is INavSubNav => {
  return (navHeaderLink as INavSubNav).sys.contentType.sys.id === NavHeaderLinkTypes.NavSubNav;
};
