export function env(key: string): string {
  return (process || {}).env?.[key] || 'NA';
}

export function formatPhoneNumber(phone: string): string {
  const areaCode = phone.slice(0, 3);
  const exchange = phone.slice(3, 6);
  const line = phone.slice(6, 10);

  return `(${areaCode}) ${exchange}-${line}`;
}

/**
 * format used only for structured data
 */
export function structuredPhoneNumber(phone: string): string {
  const areaCode = phone.slice(0, 3);
  const exchange = phone.slice(3, 6);
  const line = phone.slice(6, 10);

  return `+1-${areaCode}-${exchange}-${line}`;
}

export const doctorDisplayName = (doctor: {
  firstName: string;
  lastName: string;
  titles: string[];
}): string => `${doctor.firstName} ${doctor.lastName}, ${doctor.titles.join(', ')}`;

export const pluralize = (name: string): string => `${name}${name.slice(-1) === 's' ? "'" : "'s"}`;

const NUM_TO_STRING = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
];

export const numberToString = (number: number): string | number => {
  if (number > 10) return number;
  return NUM_TO_STRING[number];
};

export function possess(pluralNoun: string): string {
  return pluralNoun.replace(/s$/, "'s");
}

export const buildSrcSet = (
  url: string,
  imageWidth: number,
  size: number,
  webP = false
): string => {
  let more = '';
  if (size > 1) {
    more = `${buildSrcSet(url, imageWidth, size - 1, webP)}, `;
  }
  return `${more}${url}?w=${imageWidth * size}${webP ? '&fm=webp' : ''} ${size}x `;
};

export const convertTime12to24 = (initialTime: string): string => {
  if (!initialTime) {
    return 'Closed';
  }

  const [time, modifier] = initialTime.split(' ');
  const parts = time.split(':');
  let hours: string | number = parts[0];
  const minutes = parts[1];

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours < 10 ? `0${hours}` : hours}:${minutes}:00`;
};

export const escapeHtml = (unsafe: string): string => {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&#039;');
};

export const getItemFromLocalStorage = (key: string): string | null => {
  try {
    return window.localStorage.getItem(key);
  } catch (error) {
    return null;
  }
};

export const addItemToLocalStorage = (key: string, item: string): void => {
  try {
    window.localStorage.setItem(key, item);
  } catch (error) {
    console.log('localStorage not supported');
  }
};

export const removeItemFromLocalStorage = (key: string): void => {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    console.log('localStorage not supported');
  }
};

/**
 * Removes any field where the value is undefined.  This is useful to avoid
 * serialization errors with React props.  All React props must be serializable
 * to JSON, and JSON doesn't support undefined, so usually you want to just remove
 * the property entirely.
 */
export function removeIfUndefined<V, O extends Record<string, V | undefined>>(
  test: O
): Record<string, V> {
  return Object.entries(test).reduce<Record<string, V>>((acc, [key, val]) => {
    if (val) {
      acc[key] = val;
    }
    return acc;
  }, {});
}

/** A mapping of type URL that removes functions and readonly
 * properties while making remaining properties optional */
export type UrlObject = Omit<
  { [Property in keyof URL]+?: URL[Property] },
  'origin' | 'toString' | 'searchParams' | 'toJSON'
>;

/** a replacement for url.format since it is deprecated in Node.JS and unavailable in browser */
export function urlFrom(urlObject: UrlObject): string {
  return String(Object.assign(new URL('http://a.org'), urlObject));
}

/** Removes all duplicate values using a hashed value of each element to test for same-value-zero equality */
export function unique<T, H extends string | number>(elems: T[], hash: (elem: T) => H): T[] {
  return elems.reduce<{ found: Set<H>; acc: T[] }>(
    ({ found, acc }, elem) => {
      const h = hash(elem);
      if (found.has(h)) {
        return { found, acc };
      } else {
        found.add(h);
        acc.push(elem);

        return { found, acc };
      }
    },
    { found: new Set<H>(), acc: [] }
  ).acc;
}

/**
 * Get distance between two lat/lng coords.
 *
 * @param lat1
 * @param lng1
 * @param lat2
 * @param lng2
 * @param unit
 * @returns
 */
export const getDistance = (
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number,
  unit?: string
): number => {
  if (lat1 === lat2 && lng1 === lng2) {
    return 0;
  }

  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lng1 - lng2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit == 'K') {
    dist = dist * 1.609344;
  }
  if (unit == 'N') {
    dist = dist * 0.8684;
  }
  return dist;
};
