import { Dispatch, ReactElement, SetStateAction, useEffect, useRef } from 'react';
import { ArrowDropDown } from '@material-ui/icons';
import useClickAway from '../../../hooks/useClickAway';
import { INavSubNav } from 'types/contentful';
import NavLink from '../Nav/NavLink';

interface SubNavLinkProps {
  link: INavSubNav;
  currentSlug: string;
  close: Dispatch<SetStateAction<boolean>>;
}

export default function MobileSubNavLink({ link, currentSlug }: SubNavLinkProps): ReactElement {
  const { ref, isVisible, setIsVisible } = useClickAway(false);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (listRef.current !== null) {
      const height = listRef.current.scrollHeight;

      if (isVisible) {
        listRef.current.style.height = height + 'px';
      } else {
        listRef.current.style.height = '0px';
      }
    }
  }, [isVisible, listRef]);

  const handleKeyPress = (): void => {
    return;
  };

  return (
    <div
      ref={ref}
      role="menuitem"
      tabIndex={-1}
      onClick={() => setIsVisible(!isVisible)}
      onKeyPress={handleKeyPress}
    >
      <div
        className={`w-full flex justify-between border-b-3 cursor-pointer transition duration-300 ease-in-out ${
          isVisible ? ' border-black' : 'border-transparent'
        }`}
      >
        {link.fields.text}

        <div
          className={`inline-block transition transform duration-300 ${
            isVisible ? '-rotate-180' : 'rotate-0'
          }`}
        >
          <ArrowDropDown />
        </div>
      </div>

      <div
        ref={listRef}
        className={`transition-height duration-500 space-y-6 ml-6 overflow-hidden ${
          isVisible ? 'mt-6' : ''
        }`}
        style={{ height: '0px' }}
      >
        {link?.fields?.links &&
          link.fields.links.map((link, i) => {
            return (
              <div key={i + link.fields.text}>
                <NavLink
                  currentSlug={currentSlug}
                  classNames={`block ${currentSlug === link.fields.slug ? 'font-bold' : ''}`}
                  {...link.fields}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}
