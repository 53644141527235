import { Category, Product, Variant } from './bigcomm/client';
import { FacetType, FacetValue, VariantOptions } from './searchspring/client';
import { ParsedUrlQuery } from 'querystring';

interface Activity {
  [eyeglasses: string]: string;
  sunglasses: string;
  kids: string;
}

interface Tokens {
  [color: string]: string;
  material: string;
  rim: string;
  profitCenter: string;
  brand: string;
  frameType: string;
  activity: string;
}

// this scheme and host are only used to generate a valid URL
export const DUMMY_URL_BASE = 'http://ignore';

const DEFAULT_ACTIVITIES: Activity = {
  eyeglasses: 'focusing at work or relaxing at home',
  sunglasses: 'enjoying time outdoors and catching some sun',
  kids: 'doing homework or playing with friends',
};

const DEFAULT_DESCRIPTION = `Try on the {name} at your local {profitCenter} location.
  These {color} designer {frameType} from {brand} are perfect for {activity}.
  Made from {material}, these {frameType} feature a {rim} design and can help you see clearly with prescription lenses.
  At {profitCenter}, we can build a complete pair of {brand} {name} just for you, with custom lenses designed for your vision needs.`;

/**
 * Template-ize the Product Description
 */
export function buildProductDescription(
  product: Product,
  variant: Variant,
  profitCenter: string,
  brand: string,
  category: Category
): string {
  const categoryName = category.name.toLocaleLowerCase();
  const [{ name: childCategory }] = category.children;
  const frameType = childCategory === 'Kids' ? `kids ${categoryName}` : categoryName;
  const activityType = childCategory === 'Kids' ? 'kids' : categoryName;

  const TOKENS: Tokens = {
    name: product.name,
    color: variantColorValue(variant)?.trim().toLocaleLowerCase() || '',
    material: customFieldValue(product, 'Material')?.trim().toLocaleLowerCase() || '',
    rim: customFieldValue(product, 'Rim Style')?.trim().toLocaleLowerCase() || '',
    profitCenter,
    brand,
    frameType,
    activity: DEFAULT_ACTIVITIES[activityType],
  };

  return Object.keys(TOKENS).reduce(
    (acc, token) => acc.replace(new RegExp(`{${token}}`, 'gi'), TOKENS[token]),
    DEFAULT_DESCRIPTION
  );
}

/**
 * Helper function to get variant options
 */
function variantOptionValue(variant: Variant, name: string): string | undefined {
  return variant.option_values.find((option) => option.option_display_name === name)?.label;
}

export function variantColorValue(variant: Variant): string | undefined {
  return variantOptionValue(variant, 'Color');
}

/**
 * Helper function to get custom fields from product
 */
export function customFieldValue(product: Product, name: string): string | undefined {
  return product.custom_fields.find((field) => field.name === name)?.value;
}

export function newPathWithQueryParam(href: string, paramName: string, value: string): string {
  const url = new URL(DUMMY_URL_BASE + href);
  url.searchParams.set(paramName, value);

  return `${url.pathname}${url.search}`;
}

export function handleFilterChange(
  path: string,
  query: ParsedUrlQuery,
  field: string,
  facet: FacetValue
): string {
  let url = '';
  let hasParam = false;
  if (facet.type === FacetType.VALUE) {
    hasParam = query[`filter.${field}`]?.includes(facet.value as string) || false;
    if (!hasParam) {
      url = appendFilterToQueryParam(path, field, facet);
    } else {
      url = removeFilterFromQueryParam(path, field, facet);
    }
  } else if (facet.type === FacetType.RANGE) {
    hasParam =
      query[`filter.${field}.low`] === facet.low && query[`filter.${field}.high`] === facet.high;

    // always remove the range filter and then re-add if needed, since only 1 can exist at a time
    url = removeFilterFromQueryParam(path, field, facet);
    if (!hasParam) {
      url = appendFilterToQueryParam(url, field, facet);
    }
  }
  return url;
}

export function appendFilterToQueryParam(href: string, field: string, facet: FacetValue): string {
  if (facet.type === FacetType.VALUE) {
    return appendQueryParam(href, `filter.${field}`, facet.value as string);
  } else if (facet.type === FacetType.RANGE) {
    const low = appendQueryParam(href, `filter.${field}.low`, facet.low as string);
    return appendQueryParam(low, `filter.${field}.high`, facet.high as string);
  }
  return href;
}

export function appendQueryParam(href: string, paramName: string, value: string): string {
  const url = new URL(DUMMY_URL_BASE + href);
  url.searchParams.append(paramName, value);
  url.searchParams.delete('page');

  return `${url.pathname}${url.search}`;
}

export function removeFilterFromQueryParam(href: string, field: string, facet: FacetValue): string {
  if (facet.type === FacetType.VALUE) {
    return removeSingleQueryParamValue(href, `filter.${field}`, facet.value as string);
  } else if (facet.type === FacetType.RANGE) {
    const low = removeSingleQueryParam(href, `filter.${field}.low`);
    return removeSingleQueryParam(low, `filter.${field}.high`);
  }
  return href;
}

export function removeSingleQueryParam(href: string, paramName: string): string {
  const url = new URL(DUMMY_URL_BASE + href);
  url.searchParams.delete(paramName);
  url.searchParams.delete('page');

  return `${url.pathname}${url.search}`;
}

export function removeSingleQueryParamValue(
  href: string,
  paramName: string,
  entry: string
): string {
  const url = new URL(DUMMY_URL_BASE + href);
  const filtered = url.searchParams.getAll(paramName).filter((param) => param !== entry);
  url.searchParams.delete(paramName);
  url.searchParams.delete('page');

  filtered.forEach((param) => url.searchParams.append(paramName, param));

  return `${url.pathname}${url.search}`;
}

export function removeMatchingQueryParam(href: string, prefix: string): string {
  const url = new URL(DUMMY_URL_BASE + href);

  url.searchParams.forEach((value, key) => {
    if (key.startsWith(prefix)) {
      url.searchParams.delete(key);
    }
  });

  return `${url.pathname}${url.search}`;
}

export function buildProductUrl(
  productName: string,
  productColor = '',
  productId: string | number,
  variantIdentifier: string | number
): string {
  const slug = buildProductSlug(productName, productColor, productId, variantIdentifier);
  return `/shop/products/${slug}`;
}

export function buildProductSlug(
  productName: string,
  productColor = '',
  productId: string | number,
  variantIdentifier: string | number
): string {
  const nameAndColor = encodeURIComponent(`${productName}-${productColor}`.replace(/[\W_]+/g, '-'));
  return `${nameAndColor}-${productId}-${variantIdentifier}`.toLocaleLowerCase();
}

export function sortVariants(variants: {
  [key: string]: VariantOptions;
}): [string, VariantOptions][] {
  return Object.entries(variants).sort((a, b) =>
    (a[1].color || '') < (b[1].color || '') ? -1 : 1
  );
}
