import type { Asset } from 'contentful';
import { ReactElement } from 'react';

export default function Image({
  classNames,
  image,
  query,
}: {
  image: Asset;
  classNames?: string;
  query?: string;
}): ReactElement {
  if (!image) {
    return <></>;
  }
  const {
    fields: {
      file: { url, fileName },
      title,
      description,
    },
  } = image;
  const src = query ? `${url}?${query}` : url;
  const webpAble = !fileName.includes('.svg');
  const webpSrc = query ? `${src}&fm=webp` : `${src}?fm=webp`;

  return (
    <picture>
      {webpAble && <source srcSet={webpSrc} type="image/webp" />}
      <img src={src} alt={description || title} className={`${classNames}`} />
    </picture>
  );
}
