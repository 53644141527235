import React, { ReactElement } from 'react';
import NavLink, { NavLinkProps } from '../Nav/NavLink';

export default function MobileNavLInk({ currentSlug, ...props }: NavLinkProps): ReactElement {
  return (
    <div className={`${currentSlug === props.slug ? 'border-b-3 border-black' : ''}`}>
      <NavLink
        currentSlug={currentSlug}
        classNames={`block w-full ${currentSlug === props.slug ? 'font-bold' : ''}`}
        {...props}
      />
    </div>
  );
}
