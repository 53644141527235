import { ReactElement } from 'react';
import { IFragmentPhoneNumber } from 'types/contentful';

interface ContactNumberProps {
  numbers?: IFragmentPhoneNumber[];
  isMobile?: boolean;
}

const mobileCssClasses = 'flex justify-around max-w-xs';
const desktopCssClasses = 'flex flex-col justify-center mr-4';

function formatNumber(arg: string): string {
  return `${arg.slice(0, 3)}-${arg.slice(3, 6)}-${arg.slice(6, 10)}`;
}

export default function HeaderContactNumbers({
  numbers,
  isMobile,
}: ContactNumberProps): ReactElement {
  return (
    <div className={isMobile ? mobileCssClasses : desktopCssClasses}>
      {numbers?.map((phone, index) => {
        if (phone.fields.type === 'Phone') {
          return (
            <a key={index} href={`tel:${phone.fields.number}`} target="" rel="noreferrer">
              <span className="flex items-center italic">
                <img
                  src={`/images/${phone.fields.type}.svg`}
                  alt="phone icon"
                  className={isMobile ? 'mr-1' : 'mr-2'}
                />
                {formatNumber(phone.fields.number)}
              </span>
            </a>
          );
        } else {
          return (
            <span key={index} className="flex items-center italic">
              <img src="/images/Fax.svg" alt="phone icon" className={isMobile ? 'mr-1' : 'mr-2'} />
              {formatNumber(phone.fields.number)}
            </span>
          );
        }
      })}
    </div>
  );
}
