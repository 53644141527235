export const LOCATION_SELECT_FIELDS =
  'fields.name,fields.slug,fields.address1,fields.address2,fields.address3,fields.city,fields.state,fields.zipCode,fields.map,fields.phoneNumber,fields.schedulingUrl,fields.disableScheduling';

export const MAX_SCREEN_WIDTH = 1440;

export const COMPARISON_KEY = 'frame_comparison_skus';

export const FRAMES_IMAGE_PLACEHOLDER = '/images/frame-placeholder.svg';

export const FRESHPAINT_VIDEO_URL = 'https://freshpaint-video.com';
