import React, { ReactElement } from 'react';
import { FRAMES_IMAGE_PLACEHOLDER } from 'lib/consts';
import { buildSrcSet } from 'lib/util';

const MAX_IMAGE_WIDTH = 320;

export default function ProductSearchImage({
  imageUrl,
  productName,
}: {
  imageUrl: string;
  productName: string;
}): ReactElement {
  if (!imageUrl) {
    return (
      <img
        src={FRAMES_IMAGE_PLACEHOLDER}
        alt="coming soon"
        style={{ width: MAX_IMAGE_WIDTH }}
        className="mx-auto"
      />
    );
  }

  return (
    <picture>
      <source srcSet={`${buildSrcSet(imageUrl, MAX_IMAGE_WIDTH, 2, true)}`} type="image/webp" />
      <img
        src={`${imageUrl}?w=${MAX_IMAGE_WIDTH}`}
        srcSet={`${buildSrcSet(imageUrl, MAX_IMAGE_WIDTH, 2)}`}
        alt={productName}
        style={{ width: 320, height: 160 }}
        className="mx-auto"
      />
    </picture>
  );
}
