import ErrorIcon from '@material-ui/icons/ErrorOutline';
import CloseIcone from '@material-ui/icons/Close';
import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import RichText from './Richtext';
import type { Document } from '@contentful/rich-text-types';
import { addItemToLocalStorage, getItemFromLocalStorage } from 'lib/util';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

interface WarningBannerProps {
  text: Document;
  colorScheme: string;
  openInNewTab?: boolean | undefined;
  setOnBannerRemove: (onBannerRemove: boolean) => void;
}

// Keys for Local Storage
const text = 'header_alert_banner_text';

function setColors(colorScheme: string): string {
  const textColor = colorScheme !== 'Highlight' ? 'white' : 'black';
  return `bg-${colorScheme.toLowerCase()} text-${textColor}`;
}

// We need extra padding to stop banner text from overlapping the close icon
function closeIconPadding(colorScheme: string): string | void {
  if (colorScheme !== 'Red') {
    return 'pr-8';
  }
}

/*
-- Logic for function isDisplayable --
We are using local storage to manage the state of the banner.
1. If there is a value in local storage and it's the same as the current one, then we've already dismissed it.
2. If there isn't a value in local storage, then we've never dismissed an alert.
3. If there is a different value in local storage, then we've dismissed a previous alert.
*/
function isDisplayable(textFromProps: string): boolean {
  return !(getItemFromLocalStorage(text) && getItemFromLocalStorage(text) === textFromProps);
}

function dismissAlertBanner(textFromProps: string, hook: Dispatch<SetStateAction<boolean>>): void {
  addItemToLocalStorage(text, textFromProps);
  hook(false);
}

export default function HeaderAlertBanner({
  text,
  colorScheme,
  openInNewTab,
  setOnBannerRemove,
}: WarningBannerProps): ReactElement | null {
  const [displayBanner, setDisplayBanner] = useState(true);
  const plainText = documentToPlainTextString(text);

  useEffect(() => {
    if (!isDisplayable(plainText)) {
      setDisplayBanner(false);
    }
  }, [displayBanner, plainText]);

  const onCloseBanner = (): void => {
    setOnBannerRemove(true);
    dismissAlertBanner(plainText, setDisplayBanner);
  };

  // Always display a red banner
  if (displayBanner || colorScheme === 'Red') {
    return (
      <div
        className={`py-4 px-2 ${closeIconPadding(colorScheme)} ${setColors(
          colorScheme
        )} flex justify-center items-center`}
      >
        <ErrorIcon
          className="errorIcon"
          style={{ fontSize: '20px', width: '20px', marginRight: '6px' }}
        />
        {text !== undefined && (
          <div className={openInNewTab ? 'openInNewTab' : ''}>
            <RichText document={text} />
          </div>
        )}
        {colorScheme !== 'Red' && (
          <CloseIcone
            className="closeIcon fixed top-2 right-0 pr-4 cursor-pointer"
            fontSize="large"
            onClick={onCloseBanner}
            role="button"
            tabIndex={0}
            style={{ width: '35px !important', fontSize: '35px !important' }}
          />
        )}
      </div>
    );
  } else {
    return <></>;
  }
}
