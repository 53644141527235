import { ReactElement, FocusEvent } from 'react';
import { INavLinkFields } from 'types/contentful';
import Link from '../Link';

export interface NavLinkProps extends INavLinkFields {
  currentSlug: string;
  classNames?: string;
  onBlur?: (e: FocusEvent<HTMLAnchorElement>) => void;
}

const checkIsExternal = (slug: string): boolean =>
  slug.startsWith('http') || slug.startsWith('tel:') || slug.startsWith('mailto:');

export default function NavLink({
  currentSlug,
  slug,
  text,
  openInNewTab,
  classNames = '',
  ...props
}: NavLinkProps): ReactElement {
  const isExternal = checkIsExternal(slug);
  const target = openInNewTab !== undefined && openInNewTab === true ? '_blank' : '';

  const Internal = (): ReactElement => {
    const [querylessSlug, query] = slug.split('?');
    return (
      <Link slug={querylessSlug} query={query}>
        <a
          target={target}
          className={classNames}
          aria-current={currentSlug === querylessSlug ? 'page' : undefined}
          {...props}
        >
          {text}
        </a>
      </Link>
    );
  };

  const External = (): ReactElement => (
    <a
      href={slug}
      rel="noreferrer"
      target={target}
      className={classNames}
      aria-current={currentSlug === slug ? 'page' : undefined}
      {...props}
    >
      {text}
    </a>
  );

  return isExternal ? <External /> : <Internal />;
}
