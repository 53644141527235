import { useState, useEffect, useRef, Dispatch, SetStateAction, RefObject } from 'react';

export default function useClickAway(
  initialIsVisible: boolean
): {
  ref: RefObject<HTMLDivElement>;
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
} {
  const [isVisible, setIsVisible] = useState<boolean>(initialIsVisible);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent | TouchEvent): void => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isVisible, setIsVisible };
}
