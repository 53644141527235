import { useRef, useEffect, useCallback } from 'react';

interface Position {
  x: number;
  y: number;
}

const isBrowser = typeof window !== 'undefined';

function getScrollPosition(): Position {
  if (!isBrowser) return { x: 0, y: 0 };

  return { x: window.pageXOffset, y: window.pageYOffset };
}

export function useScrollPosition(
  effect: ({ prevPos, currPos }: { prevPos: Position; currPos: Position }) => void,
  deps: unknown[]
): void {
  const position = useRef(getScrollPosition());

  const callBack = useCallback((): void => {
    const currPos = getScrollPosition();
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
  }, [effect]);

  useEffect(() => {
    const handleScroll = (): void => callBack();

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [deps, callBack]);
}
