import { BrandColors } from 'lib/routing';
import { createContext } from 'react';
import { IEcommIds } from 'types/contentful';

interface ISiteDataContext {
  domain: string;
  ecommIds: IEcommIds | null;
  colors: BrandColors | null;
}

const SiteDataContext = createContext<ISiteDataContext>({
  domain: '',
  ecommIds: null,
  colors: null,
});

export default SiteDataContext;
